import SettingsItem from 'components/SettingsItem';
import { User } from 'lib';
import { USER_ROLE_MASTER, USER_ROLE_ROOFER } from 'lib/util';
import React from 'react';

interface DefaultViewSettingWrapperProps {
    viewEnabled:{
        news: boolean,
        orders: boolean,
        new_order: boolean,
        billings: boolean,
        promotion: boolean,
        isod: boolean,
        account: boolean,
        settings: boolean,
    };
    user: User | null;
    defaultView: string;
    isDesktop: boolean;
    handleDefaultViewChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DefaultViewSettingWrapper = ({ viewEnabled, user, defaultView, isDesktop, handleDefaultViewChange }: DefaultViewSettingWrapperProps) => (
    <SettingsItem
        inputType='select'
        customOptions={[
            ...(viewEnabled.news ? [{ value: 'Akt', label: 'Aktualności' }] : []),
            ...(viewEnabled.orders && user?.role !== USER_ROLE_ROOFER ? [{ value: 'Zam', label: 'Zamówienia' }] : []),
            ...(viewEnabled.new_order && user?.role !== USER_ROLE_ROOFER ? [{ value: 'NZam', label: 'Nowe zamówienie' }] : []),
            ...(viewEnabled.billings && user?.role !== USER_ROLE_ROOFER ? [{ value: 'Roz', label: 'Rozliczenia' }] : []),
            ...(viewEnabled.promotion ? [{ value: 'Wsp', label: 'Wspólne korzyści' }] : []),
            ...(viewEnabled.isod && user?.role !== USER_ROLE_ROOFER ? [{ value: 'Isod', label: 'Isod' }] : []),
            ...(viewEnabled.account && user?.role !== USER_ROLE_MASTER ? [{ value: 'Konto', label: 'Konto' }] : []),
            ...(user?.permissions.anyAdminTabPermission() ? [{ value: 'Adm', label: 'Admin' }] : []),
            ...(user?.role !== USER_ROLE_ROOFER ? [{ value: 'Kon', label: 'Konkursy' }] : []),
            ...(viewEnabled.settings ? [{ value: 'Ust', label: 'Ustawienia' }] : [])
        ]}
        id='defaultView'
        value={defaultView}
        changeHandler={handleDefaultViewChange}
        additionalStyles={{ width: isDesktop ? '300px' : '220px', height: '37px' }}

    />
);

export default DefaultViewSettingWrapper;
