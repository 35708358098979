import store from 'store';
import { dataActions, UserRegistrationInfo } from 'reducers';
import { produce } from 'immer';
import { API_ADDRESS, post, put, get, del } from 'lib/communication';
import { RooferInfoResponse, UserInfoResponse, LoginResponse, Response, ErrorResponse } from './communicationTypes';
import { RegisteredUserData, RooferInfo, User, Param, ServiceWorkType, Permissions, UserDeliveryAddress } from 'lib/types';
import { userService } from 'services';

const setUserInfoFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setUserInfoFetching(isFetching));
};

const mapLoginResponseToUser = (loginResponse: LoginResponse): User => {
    const { user, jwt, environment } = loginResponse;
    return {
        id: user.id,
        login: user.login,
        username: user.username,
        email: user.email,
        role: user.role,
        permissions: new Permissions(user.permissions),
        name: user.name,
        phone: user.phone,
        surname: user.surname,
        token: jwt.token,
        tokenExpiration: jwt.tokenExpiration,
        company: user.company,
        companyIsod: user.companyIsod,
        guardian: user.guardian,
        guardianMail: user.guardianMail,
        guardianPhone: user.guardianPhone,
        mag: user.mag,
        logo: user.logo,
        forcePwdChange: user.forcePwdChange !== '0',
        environment,
        promotionalProgramEnroll: user.promotionalProgramEnroll,
        promotionTermsAccept: user.promotionTermsAccept,
        promotionTermsChange: user.promotionTermsChange,
        wydr: user.wydr,
        currency: user.currency,
        params: user.params
    };
};

export const getUserInfo = async (
    login: string
): Promise<boolean> => {
    const route: string = 'user/getUserData';
    setUserInfoFetching(true);
    try {
        const data = await post<UserInfoResponse>(`${API_ADDRESS}${route}`, { login });

        if (data.ok && data.parsedBody) {
            const user = data.parsedBody;
            const state = store.getState();
            let setUser: UserRegistrationInfo;
            if (state.data.userInfo.userInfo !== null) {
                setUser = produce(state.data.userInfo.userInfo, (draft) => {
                    draft.company.name = user.company;
                    draft.company.regon = String(user.regon);
                    draft.user.firstName = user.name;
                    draft.user.lastName = user.surname;
                    draft.user.street = user.street;
                    draft.user.buildingNo = user.building;
                    draft.user.apartmentNo = user.apartment;
                    draft.user.postalCode = user.postalCode;
                    draft.user.town = user.town;
                    draft.user.nip = String(user.nip);
                    draft.user.pesel = user.pesel;
                    draft.user.treasuryOffice = user.taxOffice;
                    draft.user.phone = String(user.phone);
                    draft.user.email = user.email;
                });
            } else {
                setUser = {
                    user: {
                        firstName: user.name,
                        lastName: user.surname,
                        street: user.street,
                        buildingNo: user.building,
                        apartmentNo: user.apartment,
                        postalCode: user.postalCode,
                        town: user.town,
                        nip: String(user.nip),
                        pesel: user.pesel,
                        treasuryOffice: user.taxOffice,
                        phone: String(user.phone),
                        email: user.email
                    },
                    company: {
                        name: user.company,
                        regon: String(user.regon)
                    }
                };
            }
            store.dispatch(dataActions.setUserInfo(setUser));

            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setUserInfoFetching(false);
    }
};

export const updateUserData = async (
    login: string,
    type: string,
    user: UserRegistrationInfo
): Promise<boolean> => {
    const route: string = 'user/updateUserData';
    setUserInfoFetching(true);
    const pesel: string = user.user.pesel === undefined ? '' : user.user.pesel;
    const taxOffice: string = user.user.treasuryOffice === undefined ? '' : user.user.treasuryOffice;
    try {
        const data = await put<Response>(`${API_ADDRESS}${route}`, {
            login,
            name: user.user.firstName,
            surname: user.user.lastName,
            street: user.user.street,
            building: user.user.buildingNo,
            apartment: user.user.apartmentNo,
            postalCode: user.user.postalCode,
            town: user.user.town,
            nip: user.user.nip,
            regon: user.company.regon,
            pesel,
            taxOffice,
            phone: user.user.phone,
            email: user.user.email,
            company: user.company.name,
            type: type === 'trader' ? 2 : 1
        });
        await getUserInfo(login);
        if (data.ok) {
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setUserInfoFetching(false);
    }
};

export const getLogin = async (
    login: string,
    password: string
): Promise<any> => {
    const route: string = 'login';
    setUserInfoFetching(true);
    try {
        const data = await post<LoginResponse | ErrorResponse>(`${API_ADDRESS}${route}`, {
            login,
            password // CryptoJS.MD5(password).toString(),
        });
        if (data.ok && data.parsedBody) {
            if ('error' in data.parsedBody) return (data.parsedBody as ErrorResponse);
            return mapLoginResponseToUser(data.parsedBody as LoginResponse);
        } throw new Error();
    } catch (err) {
        console.log('Error ', err);
        throw err;
    } finally {
        setUserInfoFetching(false);
    }
};

export const changePassword = async (
    oldPassword: string,
    newPassword: string
) => {
    const route: string = 'changePassword';
    setUserInfoFetching(true);
    const { login, logo } = userService.getUser();
    try {
        const data = await post<Response>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            oldPassword,
            newPassword
        });
        return data.parsedBody;
    } catch (err) {
        console.log('Error ', err);
        throw err;
    } finally {
        setUserInfoFetching(false);
    }
};

export const resetPassword = async (
    login: string,
    phone: string
) => {
    const route: string = 'resetPassword';
    try {
        const data = await post<RegisteredUserData>(`${API_ADDRESS}${route}`, {
            login,
            phone
        });
        return data.parsedBody;
    } catch (err) {
        console.log('Error ', err);
        throw err;
    }
};

export const getRoofer = async (
    login: string
): Promise<RooferInfo> => {
    const route: string = 'user/getRooferInfo';
    setUserInfoFetching(true);
    try {
        const data = await post<RooferInfoResponse>(`${API_ADDRESS}${route}`, { rooferLogin: login });

        if (data.ok && data.parsedBody) {
            return data.parsedBody as RooferInfo;
        }

        throw new Error();
    } catch (err) {
        console.log(err);

        return { serverError: true } as RooferInfo;
    } finally {
        setUserInfoFetching(false);
    }
};

export const updateUserParams = async (
    ...newParams: Param[]
): Promise<{ ok: boolean; }> => {
    const route: string = 'user/updateUserParams';
    const { login, logo, params } = userService.getUser();

    if (!params || !newParams) {
        return { ok: false };
    }

    setUserInfoFetching(true);

    newParams = params.map((param) => newParams.find((newParam) => newParam.ParamName === param.ParamName) || param);

    try {
        const data = await put<Response>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            params: newParams
        });

        if (data.ok) userService.updateUserParamsInSessionStorage(newParams);

        return data;
    } catch (err) {
        console.log('Error ', err);
        return { ok: false };
    } finally {
        setUserInfoFetching(false);
    }
};

export const getCurrentOrIncomingServiceWorks = async (): Promise<Boolean> => {
    const route: string = 'user/getCurrentOrIncomingServiceWorks';
    setUserInfoFetching(true);
    try {
        const data = await get<{ serviceWorks: ServiceWorkType }>(`${API_ADDRESS}${route}`);
        if (data.ok && data.parsedBody && data.parsedBody.serviceWorks) {
            const serviceWorks = {
                id: data.parsedBody.serviceWorks.id,
                from: new Date(data.parsedBody.serviceWorks.from),
                to: new Date(data.parsedBody.serviceWorks.to),
                message: data.parsedBody.serviceWorks.message
            } as ServiceWorkType;
            store.dispatch(dataActions.setCurrentOrIncomingServiceWorks(serviceWorks));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    } finally {
        setUserInfoFetching(false);
    }
};

export const getUserDeliveryAddresses = async (): Promise<Boolean> => {
    const route: string = 'user/getAddresses';
    const { login, logo } = userService.getUser();
    try {
        store.dispatch(dataActions.clearUserDeliveryAddresses());
        const data = await post<UserDeliveryAddress[]>(`${API_ADDRESS}${route}`, {
            login,
            logo
        });
        if (data.ok && data.parsedBody) {
            const addresses = data.parsedBody.map((value) => Object.assign(new UserDeliveryAddress(), value));
            store.dispatch(dataActions.setUserDeliveryAddresses(addresses));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    }
};

export const addUserDeliveryAddress = async (params: UserDeliveryAddress): Promise<string> => {
    const route: string = 'user/addAddress';
    const { login, logo } = userService.getUser();
    try {
        const data = await post<string>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            streetType: params.streetType,
            streetName: params.streetName,
            houseNumber: params.houseNumber,
            apartmentNumber: params.apartmentNumber,
            zipCode: params.zipCode,
            town: params.town,
            country: params.country,
            description: params.description
        });
        if (data.ok && data.parsedBody) {
            return data.parsedBody;
        }
        return '';
    } catch (err) {
        console.log('Error ', err);
        return '';
    }
};

export const editUserDeliveryAddress = async (params: UserDeliveryAddress): Promise<string> => {
    const route: string = 'user/editAddress';
    const { login, logo } = userService.getUser();
    try {
        const data = await put<string>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            id: params.id,
            streetType: params.streetType,
            streetName: params.streetName,
            houseNumber: params.houseNumber,
            apartmentNumber: params.apartmentNumber,
            zipCode: params.zipCode,
            town: params.town,
            country: params.country,
            description: params.description
        });
        if (data.ok && data.parsedBody) {
            return data.parsedBody;
        }
        return '';
    } catch (err) {
        console.log('Error ', err);
        return '';
    }
};

export const deleteUserDeliveryAddress = async (params: UserDeliveryAddress): Promise<Boolean> => {
    const route: string = 'user/deleteAddress';
    const { login, logo } = userService.getUser();
    try {
        const data = await del<UserDeliveryAddress[]>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            id: params.id
        });
        if (data.ok && data.parsedBody) {
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    }
};
