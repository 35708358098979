import ButtonComponent from 'components/ButtonComponent';
import DatePicker from 'components/DatePicker/DatePicker';
import { LG } from 'lib/util';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import './FilterView.scss';
import useDebounce from 'lib/hooks/useDebounce';
import { OrdersState } from '../../containers/Orders/Orders';

interface FilterViewProps {
    handleSearchSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    orderNumber: string;
    setOrderNumber: React.Dispatch<React.SetStateAction<string>>;
    customerOrderNumber: string;
    setCustomerOrderNumber: React.Dispatch<React.SetStateAction<string>>;
    from: string;
    setFrom: React.Dispatch<React.SetStateAction<string>>;
    to: string;
    setTo: React.Dispatch<React.SetStateAction<string>>;
    description: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    ordersState: OrdersState;
}

const FilterView = (props: FilterViewProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const history = useHistory();

    const [validated, setValidated] = useState(false);

    const orderNumberRef = useRef<HTMLInputElement>(null);
    const customerOrderNumberRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);

    const checkValidity = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        props.handleSearchSubmit(event);
    };

    const debouncedOrderNumber = useDebounce(props.orderNumber);
    const debouncedCustomerOrderNumber = useDebounce(props.customerOrderNumber);
    const debouncedDescription = useDebounce(props.description);

    const handleDebounce = () => {
        history.push(`${process.env.PUBLIC_URL}/orders`, {
            ...props.ordersState,
            orderNumber: props.orderNumber,
            customerOrderNumber: props.customerOrderNumber,
            description: props.description
        });
    };

    useEffect(() => {
        handleDebounce();
    }, [debouncedOrderNumber, debouncedCustomerOrderNumber, debouncedDescription]);

    useEffect(() => {
        const activeElem = (document.activeElement as HTMLElement);
        if (activeElem !== orderNumberRef.current && activeElem !== customerOrderNumberRef.current && activeElem !== descriptionRef.current) {
            activeElem.blur();
        }
    }, [history.location.state]);

    return (
        <>
            <Form
                onSubmit={checkValidity}
                className={isDesktop ? 'mb-2' : 'search-form-mobile'}
                style={{ minHeight: 112 }}
                noValidate
                validated={validated}
            >
                <div>
                    {isDesktop ? (
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>NUMER</Form.Label>
                                <Form.Control
                                    ref={orderNumberRef}
                                    type='text'
                                    placeholder='Numer zamówienia'
                                    id='orderNumber'
                                    value={props.orderNumber}
                                    onChange={(e) => props.setOrderNumber(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Form.Row} style={{ width: '72%' }}>
                                <Form.Group as={Col}>
                                    <Form.Label>NUMER KLIENTA</Form.Label>
                                    <Form.Control
                                        ref={customerOrderNumberRef}
                                        type='text'
                                        placeholder='Numer klienta'
                                        id='customerOrderNumber'
                                        value={props.customerOrderNumber}
                                        onChange={(e) => props.setCustomerOrderNumber(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>DATA OD</Form.Label>
                                    <DatePicker type='date' id='from' onChange={(e) => props.setFrom(e.target.value)} value={props.from} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>DATA DO</Form.Label>
                                    <DatePicker type='date' id='to' onChange={(e) => props.setTo(e.target.value)} value={props.to} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>OPIS</Form.Label>
                                    <Form.Control
                                        ref={descriptionRef}
                                        type='text'
                                        placeholder='Opis zamówienia/własny'
                                        id='description'
                                        value={props.description}
                                        onChange={(e) => props.setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Form.Row}>
                                <Form.Group as={Col} xs='auto' className='search-button'>
                                    <ButtonComponent
                                        type='submit'
                                        margin='0'
                                        text='Szukaj'
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>
                    ) : (
                        <>
                            <Form.Row>
                                <Form.Group className='flex-end' as={Col}>
                                    <Form.Label>NUMER ZAMÓWIENIA</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Numer zamówienia'
                                        id='orderNumber'
                                        value={props.orderNumber}
                                        onChange={(e) => props.setOrderNumber(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className='flex-end' as={Col}>
                                    <Form.Label>NUMER KLIENTA</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Numer klienta'
                                        id='customerOrderNumber'
                                        value={props.customerOrderNumber}
                                        onChange={(e) => props.setCustomerOrderNumber(e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <div>
                                <Form.Row>
                                    <Form.Group className='flex-end' as={Col}>
                                        <Form.Label>DATA OD</Form.Label>
                                        <DatePicker type='date' id='from' onChange={(e) => props.setFrom(e.target.value)} value={props.from} />
                                    </Form.Group>
                                    <Form.Group className='flex-end' as={Col}>
                                        <Form.Label>DATA DO</Form.Label>
                                        <DatePicker type='date' id='to' onChange={(e) => props.setTo(e.target.value)} value={props.to} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group className='flex-end' as={Col}>
                                        <Form.Label>OPIS ZAMÓWIENIA/WŁASNY</Form.Label>
                                        <Form.Control
                                            type='text'
                                            id='description'
                                            value={props.description}
                                            onChange={(e) => props.setDescription(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </div>
                            <Form.Row className='adv-search-buttons-row'>
                                <ButtonComponent
                                    marginTop='0'
                                    marginRight='5px'
                                    type='submit'
                                    text='Szukaj'
                                />
                            </Form.Row>
                        </>
                    )}
                </div>

            </Form>
        </>
    );
};

export default FilterView;
