import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import './Login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, dataActions, sessionActions } from 'reducers';
import { getLogin } from 'lib/communication/userInfo';
import { Link } from 'react-router-dom';
import ButtonComponent from 'components/ButtonComponent';
import { getFormatedDateDMY, getFormattedDateWithHoursAndMinutes, getFormattedTime } from 'lib/util';
import store from 'store';

interface FormElements extends HTMLFormControlsCollection {
    login: HTMLInputElement;
    password: HTMLInputElement;
}

const Login = () => {
    const [validated, setValidated] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [info, setInfo] = useState('');
    const dispatch = useDispatch();

    const serviceWorks = useSelector((state: AppState) => state.data.userInfo.currentOrIncomigServiceWorks);

    useEffect(() => {
        setInfo(error ?? (waiting ? 'Logowanie...' : ''));
    }, [error, waiting]);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setError(null);

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);

            return;
        }

        setWaiting(true);
        setValidated(false);

        const { login, password } = form.elements as FormElements;
        try {
            const user = await getLogin(login.value, password.value);
            if ('error' in user) setError(user.error);
            else {
                dispatch(sessionActions.setUser(user));
                store.dispatch(dataActions.setUserJustLoggedIn(true));
            }
        } catch (ex) {
            if ((ex as Error).message === 'Error: user blocked') {
                setError('Twoje konto zostało zablokowane');
            } else {
                setError('Błędne logowanie');
            }
            setWaiting(false);
        }

        form.reset();
    };
    return (
        <>
            {info && <p className='Login-info mb-3'>{info}</p>}
            <Form noValidate validated={validated} onSubmit={onSubmit} className='Login-body text-center'>
                {serviceWorks.id && serviceWorks.from < new Date() ? (
                    <div className='new-version-message' role='alert'>
                        <p>
                            Obecnie trwają prace serwisowe <br />
                            Planowane zakończenie prac:{' '}
                            {getFormattedDateWithHoursAndMinutes(serviceWorks.to)}
                        </p>
                        <p>{serviceWorks.message}</p>
                    </div>
                ) : (
                    <>
                        {serviceWorks.id ?
                            <div className='new-version-message' role='alert'>
                                <p>
                                    {
                                        serviceWorks.from.getDay() !== serviceWorks.to.getDay() ? (
                                            `Informujemy o planowanych pracach serwisowych w dniach od ${getFormatedDateDMY(serviceWorks.from, '.')} od godziny ${getFormattedTime(serviceWorks.from, ':')} do ${getFormatedDateDMY(serviceWorks.to, '.')} do godziny ${getFormattedTime(serviceWorks.to, ':')}. W tym okresie system będzie niedostępny dla użytkowników.`
                                        ) : (
                                            `Informujemy o planowanych pracach serwisowych w dniu ${getFormatedDateDMY(serviceWorks.from, '.')} od godziny ${getFormattedTime(serviceWorks.from, ':')} do godziny ${getFormattedTime(serviceWorks.to, ':')}. W tym okresie system będzie niedostępny dla użytkowników.`
                                        )
                                    }
                                </p>
                                <p>{serviceWorks.message}</p>
                            </div> : <></>}
                    </>
                )}
                <Form.Group controlId='login' className='text-left'>
                    <Form.Label>Login</Form.Label>
                    <Form.Control type='text' required />
                    <Form.Control.Feedback type='invalid'>Podaj login.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='password' className='text-left'>
                    <Form.Label>Hasło</Form.Label>
                    <Form.Control type='password' required />
                    <Form.Control.Feedback type='invalid'>Podaj hasło.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className=''>
                    <ButtonComponent
                        text='Zaloguj'
                        type='submit'
                        width='250px'
                    />
                </Form.Group>
            </Form>
            <div className='d-flex flex-wrap align-items-center justify-content-center flex-column'>
                <span className='ml-3'>Nie pamiętasz danych logowania?</span>
                <Link to={`${process.env.PUBLIC_URL}/resetPassword`}>Resetuj hasło</Link>
            </div>
            <div className='Login-version'>
                {process.env.REACT_APP_VERSION}
            </div>
        </>
    );
};

export default Login;
