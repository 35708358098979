import { addServiceWorks, getServiceWorks, getShutdownHours, removeServiceWorks, updateServiceWorks } from 'lib/communication/admin';
import { LG, getFormattedDateWithHoursAndMinutes } from 'lib/util';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { useMediaQuery } from 'react-responsive';
import '../Admin.scss';
import { FaRegTrashAlt, FaPlus, FaEdit, FaCheck } from 'react-icons/fa';
import DatePicker from 'components/DatePicker/DatePicker';
import { ServiceWorkType } from 'lib/types';

type UserEditStatus = 'ready' | 'ok' | 'error' | 'loading';

const ServiceWorks = () => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const shutdownHours = useSelector((state: AppState) => state.data.adminInfo.shutdownHours);
    const serviceWorks = useSelector((state: AppState) => state.data.adminInfo.serviceWorks);
    const serviceWorkFetching = useSelector((state: AppState) => state.data.adminInfo.props.isFetching);
    const [dateTimeFrom, setDateTimeFrom] = useState<string>('');
    const [dateTimeTo, setDateTimeTo] = useState<string>('');
    const [serviceWorkMessage, setServiceWorkMessage] = useState<string>('');
    const [dateTimeFromEdit, setDateTimeFromEdit] = useState<string>('');
    const [dateTimeToEdit, setDateTimeToEdit] = useState<string>('');
    const [serviceWorkMessageEdit, setServiceWorkMessageEdit] = useState<string>('');
    const [invalidDates, setInvalidDates] = useState<boolean>(false);
    const [invalidDatesEdit, setInvalidDatesEdit] = useState<boolean>(false);
    const [invalidMessage, setInvalidMessage] = useState<boolean>(false);
    const [invalidMessageEdit, setInvalidMessageEdit] = useState<boolean>(false);
    const [editedServiceWork, setEditedServiceWork] = useState<ServiceWorkType | null>(null);

    useEffect(() => {
        getServiceWorks();
        getShutdownHours();
    }, []);

    const setEditedProperties = (sw: ServiceWorkType) => {
        setEditedServiceWork(sw);
        setDateTimeFromEdit(getFormattedDateWithHoursAndMinutes(sw.from));
        setDateTimeToEdit(getFormattedDateWithHoursAndMinutes(sw.to));
        setServiceWorkMessageEdit(sw.message);
    };

    const convertDate = (originalDate: Date) => originalDate.toLocaleString([], {
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    const editServiceWorks = async (sw: ServiceWorkType) => {
        setInvalidDatesEdit(false);
        const { id, from, to, message } = sw;
        if (dateTimeFromEdit === getFormattedDateWithHoursAndMinutes(from) && dateTimeToEdit === getFormattedDateWithHoursAndMinutes(to) && serviceWorkMessageEdit === message) {
            setEditedServiceWork(null);
            return;
        }

        if (!(dateTimeFromEdit && dateTimeToEdit)) {
            setInvalidDatesEdit(true);
            return;
        }

        const fromEdit = Date.parse(dateTimeFromEdit);
        const toEdit = Date.parse(dateTimeToEdit);
        if (!(fromEdit && toEdit) || toEdit <= fromEdit) {
            setInvalidDatesEdit(true);
            return;
        }

        if (!serviceWorkMessageEdit) {
            setInvalidMessageEdit(true);
            return;
        }

        await updateServiceWorks(dateTimeFromEdit, dateTimeToEdit, serviceWorkMessageEdit, id);

        setDateTimeFromEdit('');
        setDateTimeToEdit('');
        setServiceWorkMessageEdit('');
        setInvalidDatesEdit(false);
        setInvalidMessageEdit(false);
        setEditedServiceWork(null);
        getServiceWorks();
    };

    const deleteServiceWorks = async (sw: ServiceWorkType) => {
        await removeServiceWorks(sw);
        getServiceWorks();
    };

    const planServiceWorks = async () => {
        setInvalidDates(false);
        if (!(dateTimeFrom && dateTimeTo)) {
            setInvalidDates(true);
            return;
        }

        const from = Date.parse(dateTimeFrom);
        const to = Date.parse(dateTimeTo);
        if (!(from && to) || to <= from) {
            setInvalidDates(true);
            return;
        }

        if (!serviceWorkMessage) {
            setInvalidMessage(true);
            return;
        }

        await addServiceWorks(dateTimeFrom, dateTimeTo, serviceWorkMessage);

        setDateTimeFrom('');
        setDateTimeTo('');
        setServiceWorkMessage('');
        setInvalidDates(false);
        setInvalidMessage(false);
        getServiceWorks();
    };

    return (
        <>
            <h2 className='mb-2 pb-2 pt-2'>Godziny wyłączenia systemu zamówień:</h2>
            <h2 className='mb-5 pb-2 font-weight-bold'>
                od{' '}
                {shutdownHours.from}
                :00 do{' '}
                {shutdownHours.to}
                :00
                {' '}
            </h2>
            <h2 className='mb-4'>Zaplanowane prace serwisowe </h2>
            {isDesktop ? (
                <>
                    <Table borderless striped>
                        <thead style={{ width: '100%' }}>
                            <tr>
                                <td className='col-3'>Od</td>
                                <td className='col-3'>Do</td>
                                <td className='col-6'>Wiadomość</td>
                                <td className='col-1' colSpan={2}>Opcje</td>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(serviceWorks, (sw) => {
                                const isEditing: boolean = sw === editedServiceWork;
                                return (
                                    <tr key={sw.message}>
                                        <td className='col-3'>{isEditing ? <DatePicker id='dateTimeFromEdit' className='Admin-datetime-input' isInvalid={invalidDatesEdit} type='datetime-local' value={dateTimeFromEdit} min={getFormattedDateWithHoursAndMinutes(new Date())} onChange={(e) => setDateTimeFromEdit(e.target.value)} /> : convertDate(sw.from)}</td>
                                        <td className='col-3'>{isEditing ? <DatePicker id='dateTimeToEdit' className='Admin-datetime-input' isInvalid={invalidDatesEdit} type='datetime-local' value={dateTimeToEdit} min={dateTimeFromEdit || getFormattedDateWithHoursAndMinutes(new Date())} onChange={(e) => setDateTimeToEdit(e.target.value)} /> : convertDate(sw.to)}</td>
                                        <td className='col-6' style={{ width: '4rem' }}>{isEditing ? <Form.Control id='serviceMessageEdit' isInvalid={invalidMessageEdit} value={serviceWorkMessageEdit} onChange={(e) => setServiceWorkMessageEdit(e.target.value)} /> : sw.message}</td>
                                        <td className='col-1' style={{ width: '1rem' }}>
                                            <Button
                                                variant='link'
                                                className='action-button'
                                                disabled={serviceWorkFetching}
                                                onClick={isEditing ? () => editServiceWorks(sw) : () => { setEditedProperties(sw); }}
                                            >
                                                {isEditing ? <FaCheck className={`manage-service-work-icon fa-check`} style={{ color: 'green' }} /> : <FaEdit style={{ color: 'black' }} className='manage-service-work-icon' />}
                                            </Button>
                                        </td>
                                        <td className='col-1-2' style={{ width: '1rem' }}>
                                            <Button
                                                variant='link'
                                                className='action-button'
                                                style={{ color: 'red' }}
                                                disabled={serviceWorkFetching}
                                                onClick={() => {
                                                    deleteServiceWorks(sw);
                                                }}
                                            >
                                                <FaRegTrashAlt />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td className='col-3'><DatePicker id='dateTimeFrom' isInvalid={invalidDates} type='datetime-local' value={dateTimeFrom} min={getFormattedDateWithHoursAndMinutes(new Date())} onChange={(e) => setDateTimeFrom(e.target.value)} /></td>
                                <td className='col-3'><DatePicker id='dateTimeTo' isInvalid={invalidDates} type='datetime-local' value={dateTimeTo} min={dateTimeFrom || getFormattedDateWithHoursAndMinutes(new Date())} onChange={(e) => setDateTimeTo(e.target.value)} /></td>
                                <td className='col-6' style={{ width: '4rem' }}><Form.Control id='serviceMessage' isInvalid={invalidMessage} value={serviceWorkMessage} onChange={(e) => setServiceWorkMessage(e.target.value)} /></td>
                                <td>
                                    <Button
                                        onClick={planServiceWorks}
                                        disabled={serviceWorkFetching}
                                    >
                                        <FaPlus />
                                    </Button>
                                </td>
                            </tr>
                        </tbody>

                    </Table>

                </>) :
                <>
                    {
                        _.map(serviceWorks, (sw) => {
                            const isEditing: boolean = sw === editedServiceWork;
                            return (
                                <div key={sw.message} className='mobile-user-block'>
                                    <div className='mobile-user-block-login col-6 '>
                                        <div className='pb-3'>
                                            od:&nbsp;
                                            <b>{isEditing ? <DatePicker id='dateTimeFromEdit' className='Admin-datetime-input' isInvalid={invalidDatesEdit} type='datetime-local' value={dateTimeFromEdit} min={getFormattedDateWithHoursAndMinutes(new Date(Date.now()))} onChange={(e) => setDateTimeFromEdit(e.target.value)} /> : convertDate(sw.from)}</b>
                                        </div>
                                        <div>
                                            do:&nbsp;
                                            <b>{isEditing ? <DatePicker id='dateTimeToEdit' className='Admin-datetime-input' isInvalid={invalidDatesEdit} type='datetime-local' value={dateTimeToEdit} min={dateTimeFromEdit || getFormattedDateWithHoursAndMinutes(new Date(Date.now()))} onChange={(e) => setDateTimeToEdit(e.target.value)} /> : convertDate(sw.to)}</b>
                                        </div>
                                    </div>
                                    <div className='mobile-user-block-administrator col-6'>{isEditing ? <Form.Control id='serviceMessageEdit' isInvalid={invalidMessageEdit} value={serviceWorkMessageEdit} onChange={(e) => setServiceWorkMessageEdit(e.target.value)} /> : sw.message}</div>
                                    <div>
                                        <Button
                                            variant='link'
                                            className='action-button'
                                            disabled={serviceWorkFetching}
                                            onClick={isEditing ? () => editServiceWorks(sw) : () => { setEditedProperties(sw); }}
                                        >
                                            {isEditing ? <FaCheck className={`manage-service-work-icon fa-check`} style={{ color: 'green' }} /> : <FaEdit className='manage-service-work-icon' style={{ color: 'black' }} />}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            variant='link'
                                            className='action-button'
                                            style={{ color: 'red' }}
                                            disabled={serviceWorkFetching}
                                            onClick={() => {
                                                deleteServiceWorks(sw);
                                            }}
                                        >
                                            <FaRegTrashAlt />
                                        </Button>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className='mobile-user-block'>
                        <div className='mobile-user-block-login col-6 '>
                            <div className='pb-4'>
                                od:
                                {' '}
                                <DatePicker id='dateTimeFrom' className='Admin-datetime-input' isInvalid={invalidDates} type='datetime-local' value={dateTimeFrom} min={getFormattedDateWithHoursAndMinutes(new Date(Date.now()))} onChange={(e) => setDateTimeFrom(e.target.value)} />
                            </div>
                            <div>
                                do:
                                {' '}
                                <DatePicker id='dateTimeTo' className='Admin-datetime-input' isInvalid={invalidDates} type='datetime-local' value={dateTimeTo} min={dateTimeFrom || getFormattedDateWithHoursAndMinutes(new Date(Date.now()))} onChange={(e) => setDateTimeTo(e.target.value)} />
                            </div>
                        </div>
                        <div className='mobile-user-block-administrator col-6'>
                            <Form.Control id='serviceMessage' isInvalid={invalidMessage} value={serviceWorkMessage} onChange={(e) => setServiceWorkMessage(e.target.value)} />
                        </div>
                    </div>
                    <Button
                        onClick={planServiceWorks}
                        disabled={serviceWorkFetching}
                    >
                        Dodaj prace serwisowe
                    </Button>
                </>}
        </>
    );
};

export default ServiceWorks;
