import { AdminRole, UserDTO, addAdmin, getAdminRoles, getAdmins, getServiceWorks, updateAdmins } from 'lib/communication/admin';
import { LG } from 'lib/util';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Table, Form, Spinner as Spin, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { useMediaQuery } from 'react-responsive';
import '../Admin.scss';
import Spinner from 'components/Spinner';
import ButtonComponent from 'components/ButtonComponent';
import NotificationComponent, { useNotification } from 'components/NotificationComponent/NotificationComponent';
import AdminRoleTable from 'components/AdminRoleTable';
import Select from 'react-select';

type UserEditStatus = 'ready' | 'ok' | 'error' | 'loading';

type AdminRoleOptionType = {
    label: string,
    value: AdminRole
};

const Admins = () => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const serviceWorks = useSelector((state: AppState) => state.data.adminInfo.serviceWorks);
    const serviceWorkFetching = useSelector((state: AppState) => state.data.adminInfo.props.isFetching);
    const [users, setUsers] = useState<UserDTO[] | null>(null);
    const [roles, setRoles] = useState<AdminRoleOptionType[]>([]);
    const [usersToShow, setUsersToShow] = useState(users);
    const [searchString, setSearchString] = useState('');
    const [statuses, setStatuses] = useState<{ [key: number]: UserEditStatus }>([]);
    const [error, setError] = useState<number>(0); // number to allow parallel requests
    const [loading, setLoading] = useState<number>(0); // number to allow parallel requests
    const [newAdmin, setNewAdmin] = useState<string>('');
    const [newRole, setNewRole] = useState<string>('');
    const showNotification = useNotification();

    const downloadUsers = async () => {
        try {
            setLoading((prev) => prev + 1);
            const newUsers = await getAdmins();
            if (newUsers) {
                setUsers(newUsers);
                const newStatuses: { [key: number]: UserEditStatus } = {};
                _.map(users, (u) => { newStatuses[u.id] = 'ready'; });
                setStatuses(newStatuses);

                setError((prev) => prev - 1);
            } else {
                setError((prev) => prev + 1);
            }
        } catch (ex) {
            setError((prev) => prev + 1);
        } finally {
            setLoading((prev) => prev - 1);
        }
    };

    const downloadRoles = async () => {
        try {
            setLoading((prev) => prev + 1);
            const newRoles = await getAdminRoles();
            if (newRoles) {
                setRoles(newRoles.map((role) => ({ label: role.name, value: role } as AdminRoleOptionType)));
                setError((prev) => prev - 1);
            } else {
                setError((prev) => prev + 1);
            }
        } catch (ex) {
            setError((prev) => prev + 1);
        } finally {
            setLoading((prev) => prev - 1);
        }
    };

    const setStatusOf = (id: number, status: UserEditStatus) => {
        setStatuses((prev) => ({ ...prev, [id]: status }));
    };

    const makeAdmin = async (user: UserDTO, role: string | null) => {
        setStatusOf(user.id, 'loading');

        user.role = role;

        try {
            const result = await updateAdmins([user]);

            if (result) {
                setStatusOf(user.id, 'ok');
            } else setStatusOf(user.id, 'error');
        } catch (ex) {
            setStatusOf(user.id, 'error');
        }
    };

    const createNewAdmin = async () => {
        try {
            if (newAdmin === '') {
                throw new Error('Użytkownik nie może być pusty');
            }
            _.forEach(users, (value) => {
                if (value.login === newAdmin) {
                    throw new Error('Użytkownik już istnieje');
                }
            });
            const result = await addAdmin([{
                id: -1,
                login: newAdmin,
                role: newRole
            }]);
            if (result) {
                showNotification('Dodano nowego administratora');
            } else {
                throw new Error('Wystąpił błąd podczas dodawania użytkownika');
            }
        } catch (ex) {
            showNotification(`Błąd! ${(ex as Error).message}`, 'danger');
        }
        setNewAdmin('');
        downloadUsers();
    };

    useEffect(() => {
        downloadUsers();
        downloadRoles();

        if (!serviceWorkFetching && !serviceWorks.length) {
            getServiceWorks();
        }
    }, []);

    useEffect(() => {
        if (roles.length) {
            setNewRole(roles[0].label);
        }
    }, [roles]);

    useEffect(() => {
        const newStatuses: { [key: number]: UserEditStatus } = {};
        _.map(users, (u) => { newStatuses[u.id] = 'ready'; });
        setStatuses(newStatuses);
    }, [users]);

    useEffect(() => {
        setUsersToShow(_.filter(users, (u) => u.login.toUpperCase().indexOf(searchString.toUpperCase()) > -1));
    }, [users, searchString]);

    return (
        <>
            <h2 className='mb-4' style={{ position: 'relative' }}>
                Zarządzanie administratorami
                {isDesktop && <NotificationComponent className='AdminNotificationComponent' />}
            </h2>
            {
                loading ? <Spinner showError={error > 0} errMsg='Błąd pobierania listy użytkowników' /> : (
                    <>
                        <Form.Control
                            className='mb-3'
                            type='text'
                            placeholder='Szukaj'
                            id='orderNumber'
                            onChange={(e) => setSearchString(e.target.value)}
                        />
                        {
                            isDesktop ? (
                                <Table borderless striped>
                                    <thead>
                                        <tr>
                                            <td>Login</td>
                                            <td>Administrator</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(usersToShow, (user) => (
                                            <tr key={user.login}>
                                                <td style={{ verticalAlign: 'middle' }}>{user.login}</td>
                                                <td>
                                                    <Select
                                                        className='Admin-roleSelect'
                                                        placeholder='Brak roli'
                                                        isClearable
                                                        isSearchable={false}
                                                        options={roles}
                                                        value={roles.find((option) => option.value.name === user.role)}
                                                        onChange={(option) => makeAdmin(user, (option as AdminRoleOptionType)?.value.name ?? null)}
                                                    />
                                                </td>
                                                <td style={{ width: '8rem', verticalAlign: 'middle' }}>
                                                    {
                                                        statuses[user.id] === 'loading' ? <Spin style={{ margin: 0 }} animation='border' size='sm' /> :
                                                            statuses[user.id] === 'ok' ? <span style={{ color: 'green' }}>OK</span> :
                                                                statuses[user.id] === 'error' ? <span style={{ color: 'red' }}>error</span> : null
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <>
                                    {
                                        _.map(usersToShow, (user) => (
                                            <div key={user.login} className='mobile-user-block'>
                                                <div className='mobile-user-block-login'>{user.login}</div>
                                                <div className='mobile-user-block-role'>
                                                    <Select
                                                        placeholder='Brak roli'
                                                        isClearable
                                                        isSearchable={false}
                                                        options={roles}
                                                        value={roles.find((option) => option.value.name === user.role)}
                                                        onChange={(option) => makeAdmin(user, (option as AdminRoleOptionType)?.value.name ?? null)}
                                                    />
                                                </div>
                                                <div className='mobile-user-block-state'>
                                                    {
                                                        statuses[user.id] === 'loading' ? <Spin style={{ margin: 0 }} animation='border' size='sm' /> :
                                                            statuses[user.id] === 'ok' ? <span style={{ color: 'green' }}>OK</span> :
                                                                statuses[user.id] === 'error' ? <span style={{ color: 'red' }}>error</span> : null
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            )
                        }
                        <Form.Row className='pb-5 mb-2' style={{ alignItems: 'center', rowGap: '1rem' }}>
                            <Col xs={6} md={8}>
                                <Form.Control
                                    type='text'
                                    placeholder='Login użytkownika'
                                    id='new_admin'
                                    onChange={(event) => { setNewAdmin(event.target.value); }}
                                />
                            </Col>
                            <Col xs={6} md={2}>
                                <Select
                                    name='new_role'
                                    isSearchable={false}
                                    options={roles}
                                    value={roles.find((option) => option.value.name === newRole)}
                                    onChange={(option) => { setNewRole((option as AdminRoleOptionType).value.name); }}
                                />
                            </Col>
                            <Col xs={12} md={2} className='search-button'>
                                <ButtonComponent
                                    type='submit'
                                    margin='auto'
                                    text='Dodaj administratora'
                                    onClick={() => { createNewAdmin(); }}
                                />
                            </Col>
                        </Form.Row>
                    </>
                )
            }
            <div style={{ overflowX: isDesktop ? 'hidden' : 'scroll' }}>
                <AdminRoleTable roles={roles.map((role) => role.value)} />
            </div>
        </>
    );
};

export default Admins;
