import { API_ADDRESS, get, post, put } from 'lib/communication';
import { CacheManagement, PermissionFlags, ServiceWorkType, ShutdownHours, Statistics } from 'lib/types';
import { dataActions } from 'reducers';
import store from 'store';

export interface UserDTO {
    id: number;
    login: string;
    role: string | null;
}

export interface AdminRole extends PermissionFlags {
    name: string;
}

const setAdminInfoFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setAdminInfoFetching(isFetching));
};

const setIsCacheManagementFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsCacheManagementFetching(isFetching));
};

export const getAdmins = async (): Promise<UserDTO[] | false> => {
    const route: string = 'admin/getAdmins';

    try {
        const data = await get<UserDTO[]>(`${API_ADDRESS}${route}`);
        if (data.ok && data.parsedBody) return data.parsedBody;
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getAdminRoles = async (): Promise<AdminRole[] | false> => {
    const route: string = 'admin/getAdminRoles';

    try {
        const data = await get<AdminRole[]>(`${API_ADDRESS}${route}`);
        if (data.ok && data.parsedBody) return data.parsedBody;
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updateAdmins = async (users: UserDTO[]): Promise<boolean> => {
    const route: string = 'admin/updateAdmins';

    try {
        const data = await put(`${API_ADDRESS}${route}`, users);
        if (data.ok && data.parsedBody) return true;
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const addAdmin = async (users: UserDTO[]): Promise<boolean> => {
    const route: string = 'admin/addAdmin';

    try {
        const data = await put(`${API_ADDRESS}${route}`, users);
        if (data.ok && data.parsedBody) return true;
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getServiceWorks = async (): Promise<Boolean> => {
    const route: string = 'admin/serviceWorks';
    setAdminInfoFetching(true);

    try {
        const data = await get<{ serviceWorks: ServiceWorkType[] }>(`${API_ADDRESS}${route}`);
        if (data.ok && data.parsedBody && data.parsedBody.serviceWorks) {
            store.dispatch(dataActions.setServiceWorks(data.parsedBody.serviceWorks.map((sw) => ({
                ...sw,
                from: new Date(sw.from),
                to: new Date(sw.to)
            }))));
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setAdminInfoFetching(false);
    }
};

export const addServiceWorks = async (from: string, to: string, message: string): Promise<Boolean> => {
    const route: string = 'admin/addServiceWorks';
    setAdminInfoFetching(true);
    try {
        const data = await put(`${API_ADDRESS}${route}`, {
            from,
            to,
            message
        });

        if (data.ok && data.parsedBody) return true;
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setAdminInfoFetching(false);
    }
};

export const updateServiceWorks = async (from: string, to: string, message: string, id: Number): Promise<Boolean> => {
    const route: string = 'admin/updateServiceWorks';
    setAdminInfoFetching(true);
    try {
        const data = await put(`${API_ADDRESS}${route}`, {
            from,
            to,
            message,
            id
        });

        if (data.ok && data.parsedBody) return true;
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setAdminInfoFetching(false);
    }
};

export const removeServiceWorks = async (sw: ServiceWorkType): Promise<Boolean> => {
    const route: string = 'admin/removeServiceWorks';
    setAdminInfoFetching(true);
    try {
        const data = await put(`${API_ADDRESS}${route}`, sw);
        if (data.ok && data.parsedBody) return true;
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setAdminInfoFetching(false);
    }
};

export const getCacheManagement = async (): Promise<Boolean> => {
    const route: string = 'admin/cacheManagement';
    setIsCacheManagementFetching(true);
    try {
        const data = await get<{ cacheManagement: CacheManagement[] }>(`${API_ADDRESS}${route}`);
        if (data.ok && data.parsedBody && data.parsedBody.cacheManagement) {
            store.dispatch(dataActions.setCacheManagement(data.parsedBody.cacheManagement.map((cm) => ({
                ...cm,
                date: cm.date !== null ? new Date(cm.date) : null
            }))));
            return true;
        }

        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setIsCacheManagementFetching(false);
    }
};

export const callArtisanCommand = async (value: string): Promise<Boolean> => {
    const route: string = 'admin/callArtisanCommand';
    try {
        const response = await post<Response>(`${API_ADDRESS}${route}`, { value });
        if (response.ok && response.parsedBody) {
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getStatistics = async (from: string, to: string) => {
    const route: string = 'admin/getStatistics';

    setAdminInfoFetching(true);
    try {
        const response = await get<Statistics>(`${API_ADDRESS}${route}?from=${from}&to=${to}`);
        if (response.ok && response.parsedBody) {
            store.dispatch(dataActions.setStatistics(response.parsedBody));
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setAdminInfoFetching(false);
    }
};

export const getShutdownHours = async (): Promise<Boolean> => {
    const route: string = 'admin/getShutdownHours';

    setAdminInfoFetching(true);
    try {
        const response = await get<ShutdownHours>(`${API_ADDRESS}${route}`);
        if (response.ok && response.parsedBody) {
            store.dispatch(dataActions.setShutdownHoursAdminInfo(response.parsedBody));
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setAdminInfoFetching(false);
    }
};
