import ButtonComponent from 'components/ButtonComponent';
import { downloadNewsDump, downloadNewsGraphics } from 'lib/communication/news';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';

const News = () => {
    const [graphicsErrMsg, setGraphicsErrMsg] = useState<string>('');
    const [dumpErrMsg, setDumpErrMsg] = useState<string>('');
    const isImgDownloadFetching = useSelector((state: AppState) => state.data.news.isNewsGraphicsFetching);
    const isDumpDownloadFetching = useSelector((state: AppState) => state.data.news.isNewsDumpFetching);

    const handleDownloadNewsGraphics = async () => {
        setGraphicsErrMsg('');
        if (!(await downloadNewsGraphics())) setGraphicsErrMsg('Nie udało się pobrać grafik');
    };

    const handleDownloadNewsDump = async () => {
        setDumpErrMsg('');
        if (!(await downloadNewsDump())) setDumpErrMsg('Nie udało się pobrać danych');
    };

    return (
        <div>
            <h2>
                Grafiki Aktualności
            </h2>
            <p>
                Tutaj możesz pobrać obecne grafiki akutualności razem z archiwum.
            </p>
            <p style={{ fontWeight: 'bold', color: 'red' }}>
                {graphicsErrMsg}
            </p>
            <br />
            <ButtonComponent
                text='Pobierz grafiki'
                onClick={handleDownloadNewsGraphics}
                margin='0.25rem'
            />
            <div style={{ fontStyle: 'italic', marginRight: 'auto' }}>
                {isImgDownloadFetching && <Spinner animation='border' />}
            </div>
            <br />

            <h2>
                News Dump
            </h2>
            <p>
                Tutaj możesz pobrać dump danych aktualności z bazy danych
            </p>
            <p style={{ fontWeight: 'bold', color: 'red' }}>
                {dumpErrMsg}
            </p>
            <br />
            <ButtonComponent
                text='Pobierz dump'
                onClick={handleDownloadNewsDump}
                margin='0.25rem'
            />
            <div style={{ fontStyle: 'italic', marginRight: 'auto' }}>
                {isDumpDownloadFetching && <Spinner animation='border' />}
            </div>
        </div>
    );
};

export default News;
