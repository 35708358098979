import ButtonComponent from 'components/ButtonComponent';
import { updateUserParams } from 'lib/communication/userInfo';
import { Param } from 'lib/types';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { DEF_WINDOW, NEW_ORDER_VIEW, NEW_ORDER_VIEW_MOBILE, ORD_PAGE_COUNT, userService } from 'services';
import './Settings.scss';
import { useSelector } from 'react-redux';
import { AppState, useUser } from 'reducers';
import { LG, USER_ROLE_ROOFER } from 'lib/util';
import { useMediaQuery } from 'react-responsive';
import { DefaultOrdersViewSettingWrapper, DefaultViewSettingWrapper, NumberOfOrdersPerPageSettingWrapper } from 'components/SettingsWrappers';

const Settings = () => {
    const [size, setSize] = useState<number>(10);
    const [defaultView, setDefaultView] = useState<string>('Akt');
    const [defaultDesktopView, setDefaultDesktopView] = useState<string>('');
    const [defaultMobileView, setDefaultMobileView] = useState<string>('');

    const isPlateCreatorOn = useSelector((state: AppState) => state.conf.isPlateCreatorOn);
    const viewEnabled = useSelector((state: AppState) => state.conf.menu);

    const [messageSize, setMessageSize] = useState<string>('');
    const [messageDefaultView, setMessageDefaultView] = useState<string>('');
    const [messageDefaultDesktopView, setMessageDefaultDesktopView] = useState<string>('');
    const [messageDefaultMobileView, setMessageDefaultMobileViwe] = useState<string>('');

    const [isSizeFetching, setIsSizeFetching] = useState<boolean>(false);
    const [isDefaultViewFetching, setIsDefaultViewFetching] = useState<boolean>(false);
    const [isDefaultDesktopViewFetching, setIsDesfaultDesktopViewFetching] = useState<boolean>(false);
    const [isDefaultMobileViewFetching, setIsDefasultMobileViewFetching] = useState<boolean>(false);

    const isDesktop = useMediaQuery({ minWidth: LG });

    const user = useUser();

    useEffect(() => {
        setSize(parseInt(userService.getUserParam(ORD_PAGE_COUNT) ?? '10'));
        setDefaultView(userService.getUserParam(DEF_WINDOW) ?? 'Akt');
        setDefaultDesktopView(userService.getUserParam(NEW_ORDER_VIEW) ?? 'basic');
        setDefaultMobileView(userService.getUserParam(NEW_ORDER_VIEW_MOBILE) ?? 'basic');
    }, []);

    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSize(parseInt(event.currentTarget.value));
    };

    const handleDefaultViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultView(event.currentTarget.value);
    };

    const handleDesktopViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultDesktopView(event.currentTarget.value);
    };

    const handleMobileViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultMobileView(event.currentTarget.value);
    };

    const handleSaveSizeChange = async () => {
        setIsSizeFetching(true);
        const updateParamPageCount: Param = {
            ParamName: ORD_PAGE_COUNT,
            ParamValue: size.toString()
        };

        const params = await updateUserParams(updateParamPageCount);
        setMessageSize(params.ok ? 'Zapisano' : 'Błąd');
        setIsSizeFetching(false);
    };

    const handleSaveDefaultViewChange = async () => {
        setIsDefaultViewFetching(true);
        const updateParamDefaultView: Param = {
            ParamName: DEF_WINDOW,
            ParamValue: defaultView
        };

        const params = await updateUserParams(updateParamDefaultView);
        setMessageDefaultView(params.ok ? 'Zapisano' : 'Błąd');
        setIsDefaultViewFetching(false);
    };

    const handleSaveDesktopViewChange = async () => {
        setIsDesfaultDesktopViewFetching(true);
        const updateParamDesktopView: Param = {
            ParamName: NEW_ORDER_VIEW,
            ParamValue: defaultDesktopView
        };

        const params = await updateUserParams(updateParamDesktopView);
        setMessageDefaultDesktopView(params.ok ? 'Zapisano' : 'Błąd');
        setIsDesfaultDesktopViewFetching(false);
    };

    const handleSaveMobileViewChange = async () => {
        setIsDefasultMobileViewFetching(true);
        const updateParamMobileView: Param = {
            ParamName: NEW_ORDER_VIEW_MOBILE,
            ParamValue: defaultMobileView
        };

        const params = await updateUserParams(updateParamMobileView);
        setMessageDefaultMobileViwe(params.ok ? 'Zapisano' : 'Błąd');
        setIsDefasultMobileViewFetching(false);
    };

    return (
        <>
            <h2>Widok domyślny</h2>
            <div className='Settings-container'>
                <DefaultViewSettingWrapper
                    viewEnabled={viewEnabled}
                    user={user}
                    defaultView={defaultView}
                    isDesktop={isDesktop}
                    handleDefaultViewChange={handleDefaultViewChange}
                />
                <ButtonComponent
                    text='Zapisz'
                    onClick={handleSaveDefaultViewChange}
                    variant='primary'
                    margin='0.25rem'
                    height='37px'
                />
                {(isDefaultViewFetching || messageDefaultView !== '') ?
                    <div className={isDesktop ? 'Settings-message' : 'Settings-message-mobile'} style={{ color: messageDefaultView === 'Błąd' ? 'red' : 'green' }}>{isDefaultViewFetching ? <Spinner className='Settings-spinner-margin' animation='border' /> : messageDefaultView}</div> :
                    <div className={isDesktop ? 'Settings-placeholder' : 'Settings-placeholder-mobile'}></div>}
            </div>
            {user?.role !== USER_ROLE_ROOFER &&
                <>
                    <h2>Zamówienia</h2>
                    <p>Ilość zamówień wyświetlanych na stronie</p>
                    <div className='Settings-container'>
                        <NumberOfOrdersPerPageSettingWrapper
                            initialValue={size}
                            changeHandler={handleSizeChange}
                            isDesktop={isDesktop}
                            id='pageSize'
                        />
                        <ButtonComponent
                            text='Zapisz'
                            onClick={handleSaveSizeChange}
                            variant='primary'
                            margin='0.25rem'
                            height='37px'
                        />
                        {(isSizeFetching || messageSize !== '') ?
                            <div className={isDesktop ? 'Settings-message' : 'Settings-message-mobile'} style={{ color: messageSize === 'Błąd' ? 'red' : 'green' }}>{isSizeFetching ? <Spinner className='Settings-spinner-margin' animation='border' /> : messageSize}</div> :
                            <div className={isDesktop ? 'Settings-placeholder' : 'Settings-placeholder-mobile'}></div>}
                    </div>
                    <h2>Nowe zamówienie</h2>
                    <p>Domyślny widok (desktopowy)</p>
                    <div className='Settings-container'>
                        <DefaultOrdersViewSettingWrapper
                            isPlateCreatorOn={isPlateCreatorOn}
                            isDesktop={isDesktop}
                            initialValue={defaultDesktopView}
                            changeHandler={handleDesktopViewChange}
                        />
                        <ButtonComponent
                            text='Zapisz'
                            onClick={handleSaveDesktopViewChange}
                            variant='primary'
                            margin='0.25rem'
                            height='37px'
                        />
                        {(isDefaultDesktopViewFetching || messageDefaultDesktopView !== '') ?
                            <div className={isDesktop ? 'Settings-message' : 'Settings-message-mobile'} style={{ color: messageDefaultDesktopView === 'Błąd' ? 'red' : 'green' }}>{isDefaultDesktopViewFetching ? <Spinner className='Settings-spinner-margin' animation='border' /> : messageDefaultDesktopView}</div> :
                            <div className={isDesktop ? 'Settings-placeholder' : 'Settings-placeholder-mobile'}></div>}
                    </div>
                    <p>Domyślny widok (mobilny)</p>
                    <div className='Settings-container'>
                        <DefaultOrdersViewSettingWrapper
                            isPlateCreatorOn={isPlateCreatorOn}
                            isForMobileView
                            isDesktop={isDesktop}
                            initialValue={defaultMobileView}
                            changeHandler={handleMobileViewChange}
                        />
                        <ButtonComponent
                            text='Zapisz'
                            onClick={handleSaveMobileViewChange}
                            variant='primary'
                            margin='0.25rem'
                            height='37px'
                        />
                        {(isDefaultMobileViewFetching || messageDefaultMobileView !== '') ?
                            <div className={isDesktop ? 'Settings-message' : 'Settings-message-mobile'} style={{ color: messageDefaultMobileView === 'Błąd' ? 'red' : 'green' }}>{isDefaultMobileViewFetching ? <Spinner className='Settings-spinner-margin' animation='border' /> : messageDefaultMobileView}</div> :
                            <div className={isDesktop ? 'Settings-placeholder' : 'Settings-placeholder-mobile'}></div>}
                    </div>
                </>}
        </>
    );
};

export default Settings;
