import React, { useState, useEffect, useMemo } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { getTodayDate } from 'lib/util';
import './Order.scss';
import _ from 'lodash';
import { AppState, dataActions } from 'reducers';
import { useSelector } from 'react-redux';
import { getOrdersList } from 'lib/communication/orders';
import { OrderDirection, CartContent, OrderStatusName, OrderStatusNameToLabel, OrderStatusNames } from 'lib/types';
import { useHistory, useLocation } from 'react-router-dom';
import { SortButtonsValue } from 'components/SortButtons/SortButtons';
import store from 'store';
import OrderStatus from './OrderStatus';
import FilterView from 'components/FilterView/FilterView';
import TableView from 'components/TableView/TableView';
import ScrollUpButton from 'components/ScrollUpButton';

export type OrdersState = {
    orderNumber: string;
    customerOrderNumber: string;
    from: string;
    to: string;
    description: string;
    statusFilter: { [NAME in OrderStatusName]: boolean };
    orderBy: string;
    orderDirection: OrderDirection;
    pageSize: number;
};

const ORDER_STATE: OrdersState = {
    orderNumber: '',
    customerOrderNumber: '',
    from: '',
    to: '',
    description: '',
    statusFilter: Object.fromEntries(_.map(OrderStatusNames, (name) => (name === 'accepted' ? [name, true] : [name, false]))) as { [NAME in OrderStatusName]: boolean },
    orderBy: 'date',
    orderDirection: OrderDirection.Desc,
    pageSize: 10
};

const Orders = () => {
    const history = useHistory();
    const location = useLocation();

    const ordersState: OrdersState = (location.state as OrdersState) || ORDER_STATE;

    const orders = useSelector((state: AppState) => state.data.orders.orders);
    const isFetchingOrders = useSelector((state: AppState) => state.data.orders.props.isFetchingOrders);

    const [error, setError] = useState(false);

    const [orderNumber, setOrderNumber] = useState('');
    const [customerOrderNumber, setCustomerOrderNumber] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [description, setDescription] = useState('');

    const [statusFilter, setStatusFilter] = useState(Object.fromEntries(_.map(OrderStatusNames, (name) => (name === 'accepted' ? [name, true] : [name, false]))) as { [NAME in OrderStatusName]: boolean });
    const statusFilterCount = useMemo(() => _.reduce(statusFilter, (acc, val) => { acc += val ? 1 : 0; return acc; }, 0), [statusFilter]);
    const [openedMobile, setOpenedMobile] = useState([false]);

    const replaceStatusFilter = (status: OrderStatusName) => Object.fromEntries(_.map(OrderStatusNames, (name) => (name === status ? [name, true] : [name, false]))) as { [NAME in OrderStatusName]: boolean };

    // orders page initial load effect
    useEffect(() => {
        store.dispatch(dataActions.clearLocalEditCart());
        store.dispatch(dataActions.setEditCart({
            summaryPrice: 0,
            itemsCount: 0,
            summaryPriceBeforeDiscount: 0,
            summaryDiscont: 0,
            summaryVat: 0,
            productGroups: [],
            gutterGroups: []
        } as CartContent));
    }, []);

    // on page navigation
    useEffect(() => {
        setOrderNumber(ordersState.orderNumber);
        setCustomerOrderNumber(ordersState.customerOrderNumber);
        setFrom(ordersState.from);
        setTo(ordersState.to);
        setDescription(ordersState.description);
        setStatusFilter(ordersState.statusFilter);
    }, [ordersState]);

    useEffect(() => {
        if (!isFetchingOrders) {
            _.forEach(_.pickBy(ordersState.statusFilter, (value) => value === true), (value, status) => {
                getOrdersList(status as OrderStatusName).then((result) => setError(!result));
            });
        }
    }, [ordersState]);

    const handleSortChange = (value: SortButtonsValue) => {
        if (value.group && value.direction) {
            setOpenedMobile([false]);
            history.push(`${process.env.PUBLIC_URL}/orders`, {
                ...ordersState,
                orderBy: value.group,
                orderDirection: value.direction,
                orderNumber,
                customerOrderNumber,
                description
            });
        }
    };

    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        let dateTo = form.to.value;
        if (form.from.value && !form.to.value) {
            dateTo = getTodayDate();
        }
        setOpenedMobile([false]);
        history.push(`${process.env.PUBLIC_URL}/orders`, {
            ...ordersState,
            orderNumber,
            customerOrderNumber,
            from,
            to: dateTo,
            description,
            statusFilter
        });
    };

    return (
        <>
            <OrderStatus />
            <Navbar className='sub-navbar' expand='lg'>
                <Nav justify variant='pills' className='flex-grow-1'>
                    {_.map(OrderStatusNameToLabel, (name, status) => (
                        <Nav.Item key={status}>
                            <Nav.Link
                                active={statusFilter[status as OrderStatusName] && statusFilterCount === 1}
                                onClick={() => {
                                    history.push(`${process.env.PUBLIC_URL}/orders`, {
                                        ...ordersState,
                                        statusFilter: replaceStatusFilter(status as OrderStatusName),
                                        orderNumber,
                                        customerOrderNumber,
                                        description
                                    });
                                }}
                            >
                                {name}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                    <Nav.Item>
                        <Nav.Link
                            active={statusFilterCount === OrderStatusNames.length}
                            onClick={() => {
                                history.push(`${process.env.PUBLIC_URL}/orders`, {
                                    ...ordersState,
                                    statusFilter: Object.fromEntries(_.map(OrderStatusNames, (name) => [name, true])) as { [NAME in OrderStatusName]: boolean },
                                    orderNumber,
                                    customerOrderNumber,
                                    description
                                });
                            }}
                        >
                            Wszystkie
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar>
            <FilterView handleSearchSubmit={handleSearchSubmit} orderNumber={orderNumber} setOrderNumber={setOrderNumber} customerOrderNumber={customerOrderNumber} setCustomerOrderNumber={setCustomerOrderNumber} from={from} setFrom={setFrom} to={to} setTo={setTo} description={description} setDescription={setDescription} ordersState={ordersState} />
            <TableView openedMobile={openedMobile} setOpenedMobile={setOpenedMobile} handleSortChange={handleSortChange} ordersState={ordersState} error={error} orderNumber={orderNumber} customerOrderNumber={customerOrderNumber} description={description} from={from} to={to} orders={orders} />
            <ScrollUpButton />
        </>
    );
};

export default Orders;
