import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState, useUser } from 'reducers';
import { getTermsAndConditions, setTermsAndConditions } from 'lib/communication/promotionalProgram';
import Spinner from 'components/Spinner/Spinner';
import { getContentFromDocument } from 'lib/util';
import ButtonComponent from 'components/ButtonComponent';
import ReactMarkdown from 'react-markdown';
import DocumentEditor from 'components/DocumentEditor/DocumentEditor';

const Terms = () => {
    const termsAndConditions = useSelector((state: AppState) => state.data.promotionalProgram.termsAndConditions);
    const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
    const termName = useSelector((state: AppState) => state.conf.promotion);
    const [error, setError] = useState(false);

    const [editModeOn, setEditModeOn] = useState<boolean>(false);
    const [content, setContent] = useState<string>('');

    const user = useUser();

    useEffect(() => {
        if (termsAndConditions.document !== undefined) {
            setContent(termsAndConditions.document !== undefined ? getContentFromDocument(termsAndConditions.document) : '');
        }
    }, [termsAndConditions]);

    useEffect(() => {
        if (termName !== '') {
            getTermsAndConditions(termName)
                .then((result) => setError(!result));
        }
    }, [termName]);

    const handleUpdate = (contentData: string, links: string[]) => {
        setTermsAndConditions(contentData, termName, links);
        setEditModeOn(false);
    };

    const onCancel = () => {
        setEditModeOn(false);
    };

    if (isFetching || error || termsAndConditions === undefined) {
        return <Spinner showError={error} />;
    }

    return (
        <div className='mx-3'>
            <div className='text-right mb-2'>
                {
                    (!editModeOn && user?.permissions.canEditTerms) && <ButtonComponent onClick={() => { setEditModeOn(true); }}>Edytuj</ButtonComponent>
                }
            </div>
            <div>
                {
                    !editModeOn ?
                        <div style={{ textAlign: 'left' }}>
                            <ReactMarkdown escapeHtml={false} source={content} />
                        </div> : user?.permissions.canEditTerms && <DocumentEditor document={termsAndConditions.document} onCancel={onCancel} onSubmit={(contentData, links) => handleUpdate(contentData, links)} />
                }
            </div>
        </div>
    );
};

export default Terms;
