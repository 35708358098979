import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { LG } from 'lib/util';
import { Param } from 'lib/types';
import { userService, ORD_PAGE_COUNT } from 'services';
import { updateUserParams } from 'lib/communication/userInfo';
import ButtonComponent from 'components/ButtonComponent';
import { NumberOfOrdersPerPageSettingWrapper } from 'components/SettingsWrappers';

interface OrdersSettingsProps {
    show: boolean;
    close: () => void;
    updateState: (newValue: number) => void;
}

const OrdersSettings = (props: OrdersSettingsProps) => {
    const isFetching = useSelector((state: AppState) => state.data.userInfo.props.isFetching);

    const isDesktop = useMediaQuery({ minWidth: LG });
    const [Size, setSize] = useState(10);
    const [settingsMessage, setSettingsMessage] = useState('');

    useEffect(() => {
        setSize(parseInt(userService.getUserParam(ORD_PAGE_COUNT) ?? '10'));
    }, []);

    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSize(parseInt(event.currentTarget.value));
    };

    const handleSave = async () => {
        const updateParamPageCount: Param = {
            ParamName: ORD_PAGE_COUNT,
            ParamValue: Size.toString()
        };

        const params = await updateUserParams(updateParamPageCount);
        setSettingsMessage(params.ok ? 'Zapisano' : 'Błąd');

        props.updateState(Size);
    };

    return (
        <>
            <Modal
                show={props.show && isDesktop}
                onHide={props.close}
                size='lg'
                dialogClassName='Order-dialog dialog-settings'
                backdrop='static'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Ustawienia
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NumberOfOrdersPerPageSettingWrapper
                        headerText='Ilość na stronie'
                        initialValue={Size}
                        changeHandler={handleSizeChange}
                        id='rowCount'
                        isDesktop={isDesktop}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        text='Zapisz'
                        onClick={handleSave}
                        variant='primary'
                        margin='0.25rem'
                    />
                    <div style={{ fontStyle: 'italic', marginRight: 'auto', color: settingsMessage === 'Błąd' ? 'red' : 'green' }}>{isFetching ? <Spinner style={{ margin: '0px' }} animation='border' /> : settingsMessage}</div>
                    <ButtonComponent
                        text='Zamknij'
                        onClick={props.close}
                        variant='secondary'
                        margin='0.25rem'
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OrdersSettings;
