import Pagination from 'components/Pagination';
import { NumberOfOrdersPerPageSettingWrapper } from 'components/SettingsWrappers';
import SortButtons, { SortButtonsValue } from 'components/SortButtons/SortButtons';
import Spinner from 'components/Spinner';
import OrderTooltip from 'containers/Orders/OrderTooltip';
import { deleteOrder, getOrderPdf, getOrderStatus } from 'lib/communication/orders';
import { Order, OrderDirection, OrderStatusName } from 'lib/types';
import { LG } from 'lib/util';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Collapse, Form, Modal, OverlayTrigger, Popover, Row, Table, Tooltip } from 'react-bootstrap';
import { BsFillGearFill } from 'react-icons/bs';
import { FaCheck, FaCopy, FaRegClock, FaRegEdit, FaRegFilePdf, FaRegTrashAlt, FaSearch, FaTimes } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { ORD_PAGE_COUNT, userService } from 'services';
import { OrdersState } from '../../containers/Orders/Orders';
import { AppState, dataActions } from 'reducers';
import OrdersSettings from 'containers/Orders/OrdersSettings';
import ButtonComponent from 'components/ButtonComponent';
import './TableView.scss';

interface TableViewProps {
    openedMobile: boolean[];
    setOpenedMobile: React.Dispatch<React.SetStateAction<boolean[]>>;
    handleSortChange: (value: SortButtonsValue) => void;
    ordersState: OrdersState;
    error: boolean;
    orderNumber: string;
    customerOrderNumber: string;
    description: string;
    from: string;
    to: string;
    orders: {
        accepted: Order[] | null;
        waiting: Order[] | null;
        processing: Order[] | null;
        completed: Order[] | null;
        closed: Order[] | null;
    };
}

const ORDER_STATUS_ICONS_MOBILE: Record<string, JSX.Element> = {
    Przyjęte: <FaCheck />,
    Oczekujące: <FaRegClock />,
    Realizowane: <FaRegClock />,
    Zrealizowane: <FaCheck />,
    Zamknięte: <FaTimes />
};

const SORT_VALUES_MOBILE: Record<string, SortButtonsValue> = {
    idDown: { direction: OrderDirection.Desc, group: 'orderNumber' },
    idUp: { direction: OrderDirection.Asc, group: 'orderNumber' },
    clientIdDown: { direction: OrderDirection.Desc, group: 'customerOrderNumber' },
    clientIdUp: { direction: OrderDirection.Asc, group: 'customerOrderNumber' },
    dateDown: { direction: OrderDirection.Desc, group: 'date' },
    dateUp: { direction: OrderDirection.Asc, group: 'date' },
    statusDown: { direction: OrderDirection.Desc, group: 'status' },
    statusUp: { direction: OrderDirection.Asc, group: 'status' },
    netDown: { direction: OrderDirection.Desc, group: 'net' },
    netUp: { direction: OrderDirection.Asc, group: 'net' },
    vatDown: { direction: OrderDirection.Desc, group: 'vat' },
    vatUp: { direction: OrderDirection.Asc, group: 'vat' },
    grossDown: { direction: OrderDirection.Desc, group: 'gross' },
    grossUp: { direction: OrderDirection.Asc, group: 'gross' },
    weightDown: { direction: OrderDirection.Desc, group: 'weight' },
    weightUp: { direction: OrderDirection.Asc, group: 'weight' }
};

const TableView = (props: TableViewProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const history = useHistory();
    const dispatch = useDispatch();

    const [maxNet, setMaxNet] = useState(0);
    const [maxVat, setMaxVat] = useState(0);
    const [maxGross, setMaxGross] = useState(0);
    const [maxWeight, setMaxWeight] = useState(0);

    const [orderBy, setOrderBy] = useState('date');
    const [orderDirection, setOrderDirection] = useState(OrderDirection.Desc);

    const [errorPdf, setErrorPdf] = useState(false);

    const orderStatus = useSelector((state: AppState) => state.data.orders.orderStatus);

    const [orderToDelete, setOrderToDelete] = useState<Order | undefined>(undefined);
    const [orderToCopy, setOrderToCopy] = useState<Order | undefined>(undefined);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteStatus, setShowDeleteStatus] = useState(false);
    const [showCopyOrderConfirmation, setShowCopyOrderConfirmation] = useState(false);

    const [showOrdersSettings, setShowOrdersSettings] = useState(false);

    const isFetchingOrders = useSelector((state: AppState) => state.data.orders.props.isFetchingOrders);
    const pdfIsFetching = useSelector((state: AppState) => state.data.orders.props.pdfIsFetching);

    const { page } = useParams<{ page: string | undefined }>();

    const [selectedFilteredOrders, setSelectedFilteredOrders] = useState([] as Order[]);

    const pageNumber = parseInt(page ?? '1') - 1;
    const calculateNumberOfPagesForPageSize = (pageSize: number): number => Math.max(1, _.ceil(_.size(selectedFilteredOrders) / pageSize));
    const numberOfPages = calculateNumberOfPagesForPageSize(props.ordersState.pageSize);
    const ordersOnPage = _.take(_.slice(selectedFilteredOrders, pageNumber * props.ordersState.pageSize), props.ordersState.pageSize);

    const getAllSelectedOrders = () => Object.keys(props.orders)
        .reduce((acc, key) => (props.ordersState.statusFilter[key as OrderStatusName] && props.orders[key as OrderStatusName] ?
            acc.concat(props.orders[key as OrderStatusName] as Order[]) : acc), [] as Order[]);

    const filterAndSetOrders = () => {
        let filteredOrders = getAllSelectedOrders();
        if (props.orderNumber) {
            filteredOrders = _.filter(filteredOrders, (order) => order.orderNumber.toLowerCase().includes(props.orderNumber.toLowerCase()));
        }

        if (props.customerOrderNumber) {
            filteredOrders = _.filter(
                filteredOrders,
                (order) => order.customerOrderNumber.toLowerCase().includes(props.customerOrderNumber.toLowerCase())
            );
        }
        if (props.from) {
            filteredOrders = _.filter(
                filteredOrders,
                (order) => new Date(props.from).getTime() - Date.parse(order.date) <= 0
            );
        }
        if (props.to) {
            filteredOrders = _.filter(
                filteredOrders,
                (order) => new Date(props.to).getTime() - Date.parse(order.date) >= 0
            );
        }
        if (props.description) {
            filteredOrders = _.filter(filteredOrders, (order) => {
                const keywords = props.description.toLowerCase().split(/[ ,]+/);
                return _.some(keywords, (key) => order.description.toLowerCase().includes(key) || order.customerDescription.toLowerCase().includes(key));
            });
        }
        setSelectedFilteredOrders(_.orderBy(filteredOrders, props.ordersState.orderBy, props.ordersState.orderDirection));
    };

    const handleEditOrder = (orderId: string) => {
        history.push({
            pathname: `${process.env.PUBLIC_URL}/new-order`,
            state: {
                ...(typeof history.location.state === 'object' ? history.location.state : {}),
                editedOrderId: orderId
            }
        });
    };

    const handleCopyOrder = (orderId: string) => {
        history.push({
            pathname: `${process.env.PUBLIC_URL}/new-order`,
            state: {
                ...(typeof history.location.state === 'object' ? history.location.state : {}),
                copiedOrderId: orderId
            }
        });
    };

    const handleOrderDelete = () => {
        setShowDeleteConfirmation(false);
        if (orderToDelete) {
            deleteOrder(orderToDelete.id).then((result) => {
                if (result) {
                    setShowDeleteStatus(true);
                }
            });
            props.setOpenedMobile([false]);
        }
    };

    // calculate width of rendered prices in table for design purpose
    const netPriceWidth = useMemo(() => {
        const d = document.createElement('div');
        d.innerHTML = maxNet.toFixed(2);
        d.classList.add('measurer');
        document.querySelector('.App-body')?.appendChild(d);
        return d.getBoundingClientRect().width;
    }, [maxNet]);

    const vatPriceWidth = useMemo(() => {
        const d = document.createElement('div');
        d.innerHTML = maxVat.toFixed(2);
        d.classList.add('measurer');
        document.querySelector('.App-body')?.appendChild(d);
        return d.getBoundingClientRect().width;
    }, [maxVat]);

    const grossPriceWidth = useMemo(() => {
        const d = document.createElement('div');
        d.innerHTML = maxGross.toFixed(2);
        d.classList.add('measurer');
        document.querySelector('.App-body')?.appendChild(d);
        return d.getBoundingClientRect().width;
    }, [maxGross]);

    const weightWidth = useMemo(() => {
        const d = document.createElement('div');
        d.innerHTML = maxWeight.toFixed(2);
        d.classList.add('measurer');
        document.querySelector('.App-body')?.appendChild(d);
        return d.getBoundingClientRect().width;
    }, [maxWeight]);

    const handlePageSizeUpdate = (newValue: number) => {
        const newNumberOfPages = calculateNumberOfPagesForPageSize(newValue);
        const pushedPageNumber = pageNumber + 1 > newNumberOfPages ? newNumberOfPages : pageNumber + 1;
        history.push(`${process.env.PUBLIC_URL}/orders/${pushedPageNumber}`, {
            ...props.ordersState,
            pageSize: newValue,
            orderNumber: props.orderNumber,
            customerOrderNumber: props.customerOrderNumber,
            description: props.description
        });
    };

    const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPageSize = parseInt(event.currentTarget.value);
        handlePageSizeUpdate(newPageSize);
    };

    const showOrderStatus = (order: Order) => {
        dispatch(dataActions.setOrderStatus({ parts: orderStatus.parts, show: true, order }));
        getOrderStatus(order.id);
    };

    // after getOrdersList finishes and sets new orders
    useEffect(() => {
        filterAndSetOrders();
    }, [props.orders]);

    useEffect(() => {
        if (ordersOnPage.length !== 0) {
            setMaxNet((_.maxBy(ordersOnPage, (order) => order.net) as Order).net);
            setMaxVat((_.maxBy(ordersOnPage, (order) => order.vat) as Order).vat);
            setMaxGross((_.maxBy(ordersOnPage, (order) => order.gross) as Order).gross);
            setMaxWeight((_.maxBy(ordersOnPage, (order) => order.weight) as Order).weight);
        }
    }, [ordersOnPage]);

    useEffect(() => {
        const newPageSize = parseInt(userService.getUserParam(ORD_PAGE_COUNT) ?? '10');
        const newNumberOfPages = calculateNumberOfPagesForPageSize(newPageSize);
        const pushedPageNumber = pageNumber + 1 > newNumberOfPages ? newNumberOfPages : pageNumber + 1;
        history.push(`${process.env.PUBLIC_URL}/orders/${pushedPageNumber}`, {
            ...props.ordersState,
            pageSize: newPageSize
        });
    }, []);

    useEffect(() => {
        if (_.size(selectedFilteredOrders) !== 0 && ordersOnPage.length <= 0) {
            history.push(`${process.env.PUBLIC_URL}/orders`, {
                ...props.ordersState,
                orderNumber: props.orderNumber,
                customerOrderNumber: props.customerOrderNumber,
                description: props.description
            });
        }
    }, [ordersOnPage]);

    useEffect(() => {
        setOrderBy(props.ordersState.orderBy);
        setOrderDirection(props.ordersState.orderDirection);
    }, [props.ordersState]);

    return (
        <>
            <OrdersSettings show={showOrdersSettings} close={() => setShowOrdersSettings(false)} updateState={handlePageSizeUpdate} />
            <Modal show={errorPdf || pdfIsFetching} onHide={() => setErrorPdf(false)} centered className='pdf-modal'>
                <Modal.Header closeButton={errorPdf} className='pdf-modal-header' />
                <Modal.Body className='pdf-modal-body'>
                    <Spinner showError={errorPdf} toPdf />
                </Modal.Body>
                <Modal.Footer className='pdf-modal-footer'>
                    {!pdfIsFetching && (
                        <ButtonComponent
                            text='Zamknij'
                            onClick={() => setErrorPdf(false)}
                        />
                    )}
                </Modal.Footer>
            </Modal>
            {isDesktop && (
                <Row className='justify-content-end'>
                    <NumberOfOrdersPerPageSettingWrapper
                        headerText='Ilość na stronie'
                        id='rowCount'
                        initialValue={props.ordersState.pageSize}
                        changeHandler={handleChangePageSize}
                        inTable
                        isDesktop={isDesktop}
                    />
                    <BsFillGearFill
                        size={24}
                        className='settings-icon'
                        onClick={() => setShowOrdersSettings(true)}
                        style={{ marginRight: 'auto' }}
                    />
                    <Col xs='auto' style={{ marginLeft: '15px' }}>
                        <div className='d-flex justify-content-end'>
                            <Pagination
                                selected={pageNumber}
                                size={numberOfPages}
                                href={(p) => `${process.env.PUBLIC_URL}/orders/${p + 1}`}
                                onSelect={(p) => history.push(`${process.env.PUBLIC_URL}/orders/${p + 1}`, props.ordersState)}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            {isDesktop ? (
                <Table className='Order-table' size='sm' borderless striped>
                    <thead>
                        <tr>
                            <th className='cell-width-15per'>
                                Numer
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'orderNumber' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-width-15per'>
                                Numer klienta
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'customerOrderNumber' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-width-9per'>
                                Data
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'date' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-width-9per'>
                                Status
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'status' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-width-9per'>
                                Netto
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'net' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-width-9per'>
                                VAT
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'vat' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-width-9per'>
                                Brutto
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'gross' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-width-9per'>
                                Waga
                                <SortButtons
                                    value={{ direction: orderDirection, group: 'weight' }}
                                    onChange={props.handleSortChange}
                                    orderBy={orderBy}
                                />
                            </th>
                            <th className='cell-max-width-60'>Opis</th>
                            <th className='cell-max-width-60'>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isFetchingOrders || props.error ?
                            <tr>
                                <td colSpan={10}><Spinner centered={false} showError={props.error} /></td>
                            </tr> :
                            <>
                                {ordersOnPage.map((order) => (
                                    <tr key={order.orderNumber}>
                                        <td>{order.orderNumber}</td>
                                        <td>{order.customerOrderNumber}</td>
                                        <td>{order.date}</td>
                                        {order.status !== null ? (
                                            <OverlayTrigger
                                                overlay={<Tooltip id='order_status_description_tooltip'>{order.status}</Tooltip>}
                                            >
                                                <td style={{ cursor: 'pointer' }} onClick={() => { showOrderStatus(order); }}>{order.status}</td>
                                            </OverlayTrigger>
                                        ) : <td style={{ cursor: 'pointer' }} onClick={() => { showOrderStatus(order); }}>{order.status}</td>}
                                        <td><div className='price-td-div net-price-td' style={{ width: netPriceWidth }}>{order.net.toFixed(2)}</div></td>
                                        <td><div className='price-td-div vat-price-td' style={{ width: vatPriceWidth }}>{order.vat.toFixed(2)}</div></td>
                                        <td><div className='price-td-div gross-price-td' style={{ width: grossPriceWidth }}>{order.gross.toFixed(2)}</div></td>
                                        <td><div className='price-td-div wieght-price-td' style={{ width: weightWidth }}>{order.weight.toFixed(2)}</div></td>
                                        <td className='cell-width-50'>
                                            {((order.description && order.description.length !== 0) || (order.customerDescription && order.customerDescription.length !== 0)) && (
                                                <OverlayTrigger overlay={<OrderTooltip
                                                    id='order_description_tooltip'
                                                    messages={{
                                                        'Opis zamówienia': order.description,
                                                        'Opis własny klienta': order.customerDescription
                                                    }}
                                                />}
                                                >
                                                    <FaSearch />
                                                </OverlayTrigger>
                                            )}
                                        </td>
                                        <td className='cell-width-140'>
                                            <OverlayTrigger
                                                trigger='click'
                                                placement='left'
                                                rootClose
                                                overlay={
                                                    <Popover className='popover-visibility' id={`order-${order.orderNumber}-action-popover`}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                            {order.isEditable ?
                                                                <Button
                                                                    variant='link'
                                                                    className='action-button'
                                                                    style={{ color: 'black' }}
                                                                    onClick={() => handleEditOrder(order.id)}
                                                                >
                                                                    <FaRegEdit />
                                                                    {' '}
                                                                    Edytuj zamówienie
                                                                </Button> :
                                                                <></>}
                                                            {order.isDeletable ?
                                                                <Button
                                                                    variant='link'
                                                                    className='action-button'
                                                                    style={{ color: 'black' }}
                                                                    disabled={order.status === 'Zamknięte'}
                                                                    onClick={() => {
                                                                        setOrderToDelete(order);
                                                                        setShowDeleteConfirmation(true);
                                                                    }}
                                                                >
                                                                    <FaRegTrashAlt />
                                                                    {' '}
                                                                    Usuń zamówienie
                                                                </Button> :
                                                                <></>}
                                                            {order.isPrintable ?
                                                                <Button
                                                                    variant='link'
                                                                    className='action-button'
                                                                    style={{ color: 'black' }}
                                                                    onClick={() => {
                                                                        getOrderPdf(order.id, order.net, order.vat, order.gross);
                                                                    }}
                                                                >
                                                                    <FaRegFilePdf />
                                                                    {' '}
                                                                    Pobierz PDF
                                                                </Button> :
                                                                <></>}
                                                            {order.isDeletable ?
                                                                <Button
                                                                    variant='link'
                                                                    className='action-button'
                                                                    style={{ color: 'black', visibility: order.isDeletable ? 'visible' : 'hidden' }}
                                                                    onClick={() => {
                                                                        setOrderToCopy(order);
                                                                        setShowCopyOrderConfirmation(true);
                                                                    }}
                                                                >
                                                                    <FaCopy />
                                                                    {' '}
                                                                    Kopiuj zamówienie
                                                                </Button> :
                                                                <></>}
                                                        </div>
                                                    </Popover>
                                                }
                                            >
                                                <Button
                                                    variant='link'
                                                    className='action-button'
                                                    style={{ color: 'black', visibility: order.isEditable ? 'visible' : 'hidden' }}
                                                >
                                                    <MdMoreHoriz />
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))}
                            </>}
                    </tbody>
                </Table>
            ) : (
                <div>
                    <div className='sort-block-mobile'>
                        <span>Sortuj:</span>
                        <Form.Control
                            as='select'
                            size='sm'
                            defaultValue='dateDown'
                            onChange={(evt) => props.handleSortChange(SORT_VALUES_MOBILE[evt.currentTarget.value])}
                        >
                            <option value='idDown'>Po numerze malejąco</option>
                            <option value='idUp'>Po numerze rosnąco</option>
                            <option value='clientIdDown'>Po numerze klienta malejąco</option>
                            <option value='clientIdUp'>Po numerze klienta rosnąco</option>
                            <option value='dateDown'>Po dacie malejąco</option>
                            <option value='dateUp'>Po dacie rosnąco</option>
                            <option value='statusDown'>Po statusie malejąco</option>
                            <option value='statusUp'>Po statusie rosnąco</option>
                            <option value='netDown'>Po cenie netto malejąco</option>
                            <option value='netUp'>Po cenie netto rosnąco</option>
                            <option value='vatDown'>Po wartości VAT malejąco</option>
                            <option value='vatUp'>Po wartości VAT rosnąco</option>
                            <option value='grossDown'>Po cenie brutto malejąco</option>
                            <option value='grossUp'>Po cenie brutto rosnąco</option>
                            <option value='weightDown'>Po wadze malejąco</option>
                            <option value='weightUp'>Po wadze rosnąco</option>
                        </Form.Control>
                    </div>
                    {isFetchingOrders || props.error ?
                        <Spinner centered={false} showError={props.error} /> :
                        <>
                            {_.take(selectedFilteredOrders, (pageNumber + 1) * props.ordersState.pageSize).map((order, index) => (
                                <div
                                    key={index}
                                    className={`Order-block-item${props.openedMobile[index] ? ' rotate' : ''}`}
                                    onClick={() => {
                                        const arr = props.openedMobile.splice(0);
                                        arr[index] = !arr[index];
                                        props.setOpenedMobile(arr);
                                    }}
                                >
                                    <div>
                                        <div className='Order-block-item-content-left'>
                                            <span>Numer:</span>
                                            <hr />
                                            <b>{order.orderNumber}</b>
                                        </div>
                                        <div
                                            className='d-flex align-items-center'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                showOrderStatus(order);
                                            }}
                                        >
                                            <span style={{ marginRight: '6px' }}>Status:</span>
                                            {ORDER_STATUS_ICONS_MOBILE[order.status]}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='Order-block-item-content-left'>
                                            <span>Numer klienta:</span>
                                            <hr />
                                            <b>{order.customerOrderNumber}</b>
                                        </div>
                                        <div className='Order-block-item-content-right'>
                                            <span>Data:</span>
                                            <hr />
                                            <b>{order.date}</b>
                                        </div>
                                    </div>
                                    <Collapse in={props.openedMobile[index]}>
                                        <section>
                                            <div>
                                                <div className='Order-block-item-content-left'>
                                                    <span>Netto:</span>
                                                    <hr />
                                                    <b>{order.net.toFixed(2)}</b>
                                                </div>
                                                <div className='Order-block-item-content-right'>
                                                    <span>Brutto:</span>
                                                    <hr />
                                                    <b>{order.gross.toFixed(2)}</b>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='Order-block-item-content-left'>
                                                    <span>VAT:</span>
                                                    <hr />
                                                    <b>{order.vat.toFixed(2)}</b>
                                                </div>
                                                <div className='Order-block-item-content-right'>
                                                    <span>Waga:</span>
                                                    <hr />
                                                    <b>{order.weight}</b>
                                                </div>
                                            </div>
                                            <div className='Order-block-item-content-left'>
                                                <span>Opis:</span>
                                                <hr />
                                                <b style={{
                                                    wordWrap: 'break-word',
                                                    maxWidth: '100%',
                                                    textAlign: 'right'
                                                }}
                                                >
                                                    {order.description}
                                                </b>
                                            </div>
                                            <div className='Order-block-item-content-left'>
                                                <span style={{ whiteSpace: 'nowrap' }}>Opis klienta:</span>
                                                <hr />
                                                <b style={{
                                                    wordWrap: 'break-word',
                                                    maxWidth: '100%',
                                                    textAlign: 'right'
                                                }}
                                                >
                                                    {order.customerDescription}
                                                </b>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                {order.isEditable ?
                                                    <Button
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                            e.stopPropagation();
                                                            handleEditOrder(order.id);
                                                        }}
                                                        variant='link'
                                                        style={{ color: 'black' }}
                                                    >
                                                        <FaRegEdit size={24} />
                                                    </Button> :
                                                    <></>}
                                                {order.isPrintable ?
                                                    <Button
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                            e.stopPropagation();
                                                            getOrderPdf(order.id, order.net, order.vat, order.gross);
                                                        }}
                                                        variant='link'
                                                        style={{ color: 'black' }}
                                                    >
                                                        <FaRegFilePdf size={24} />
                                                    </Button> :
                                                    <></>}
                                                {order.isDeletable ?
                                                    <Button
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                            e.stopPropagation();
                                                            setOrderToDelete(order);
                                                            setShowDeleteConfirmation(true);
                                                        }}
                                                        variant='link'
                                                        style={{ color: 'black' }}
                                                    >
                                                        <FaRegTrashAlt size={24} />
                                                    </Button> :
                                                    <></>}
                                                {order.isDeletable ?
                                                    <Button
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                            e.stopPropagation();
                                                            setOrderToCopy(order);
                                                            setShowCopyOrderConfirmation(true);
                                                        }}
                                                        variant='link'
                                                        style={{ color: 'black' }}
                                                    >
                                                        <FaCopy size={24} />
                                                    </Button> :
                                                    <></>}
                                            </div>
                                        </section>
                                    </Collapse>
                                </div>
                            ))}
                            <div>
                                {(pageNumber + 1) * props.ordersState.pageSize > _.size(selectedFilteredOrders) ? (<div></div>) : (
                                    <Button
                                        onClick={() => {
                                            history.push(`${process.env.PUBLIC_URL}/orders/${+(page || 1) + 1}`, props.ordersState);
                                        }}
                                        style={{ marginTop: 10 }}
                                    >
                                        Pokaż więcej
                                    </Button>
                                )}
                            </div>
                        </>}
                </div>
            )}
            {isDesktop && !isFetchingOrders && (
                <Row className='justify-content-end'>
                    <Col xs='auto' style={{ marginLeft: '15px' }}>
                        <div className='d-flex justify-content-end'>
                            <Pagination
                                selected={pageNumber}
                                size={numberOfPages}
                                href={(p) => `${process.env.PUBLIC_URL}/orders/${p + 1}`}
                                onSelect={(p) => history.push(`${process.env.PUBLIC_URL}/orders/${p + 1}`, props.ordersState)}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} backdrop='static'>
                <Modal.Body>{`Czy na pewno chcesz usunąć zamówienie o numerze ${orderToDelete?.orderNumber}`}</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowDeleteConfirmation(false)}>
                        Anuluj
                    </Button>
                    <Button variant='primary' onClick={() => handleOrderDelete()}>
                        Usuń
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteStatus} onHide={() => setShowDeleteStatus(false)} backdrop='static'>
                <Modal.Body>{`Poprawnie usunięto zamówienie o numerze ${orderToDelete?.orderNumber}`}</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowDeleteStatus(false)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showCopyOrderConfirmation} onHide={() => setShowCopyOrderConfirmation(false)} backdrop='static'>
                <Modal.Body>{`Ta akcja spowoduje wyczyszcenie koszyka i zastąpienie jego zawartości produktami zamówienia ${orderToCopy?.orderNumber}`}</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowCopyOrderConfirmation(false)}>
                        Anuluj
                    </Button>
                    <Button variant='primary' onClick={() => handleCopyOrder(orderToCopy!.id)}>
                        Kontynuuj
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TableView;
